import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

const Header = ({ logoSrc, menuItems, languageOptions }) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(languageOptions[0]);

  const [menu, setShowMenu] = useState(false);

  const dropdownRef = useRef(null);

  const handleDropdownToggle = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
    setDropdownOpen(false); // Close the dropdown after selecting a language
  };

  const handleMenu = () => {
    setShowMenu(!menu);
  };

  const handleButtonClick = (link) => {
    if (link.startsWith("http")) {
      // External link: Navigate to the URL
      window.location.href = link;
    } else {
      // Scroll to section link: Call the scrollToSection function
      scrollToSection(link.substring(1));
    }
  };

  // Handle clicks outside the dropdown to close it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <header className="bg-blue-1000">
      <nav className="py-6">
        <div className="max-w-[1206px] px-4 flex lg:flex-nowrap flex-wrap items-center justify-between mx-auto ">
          <Link to="#" className="flex items-center">
            <img src={logoSrc} class="sm:w-auto xl:h-[60px] h-[40px]" alt="" />
          </Link>
          <div className="lg:hidden block relative">
            <button
              id="dropdownDefaultButton"
              data-dropdown-toggle="dropdown"
              className="focus:ring-0 text-white leading-[26px] text-base flex items-center font-neue_montrealregular"
              type="button"
              onClick={handleDropdownToggle}
            >
              <img
                src={selectedLanguage.flag}
                className="inline-block mr-1"
                alt=""
              />
              {selectedLanguage.label}
              <svg
                className="ml-1"
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                height="8"
                viewBox="0 0 13 8"
                fill="none"
              >
                <path
                  d="M1.01172 1.08643L6.49476 6.57221L11.9833 1.08643"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
            <div
              className={`z-10 ${
                isDropdownOpen ? "" : "hidden"
              } bg-white absolute right-0 top-full divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700`}
            >
              <ul
                className="py-2 text-sm text-gray-700 dark:text-gray-200"
                aria-labelledby="dropdownDefaultButton"
              >
                {languageOptions.map((option, index) => (
                  <li key={index}>
                    <button
                      onClick={() => handleLanguageChange(option)}
                      className={`block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white w-full text-left ${
                        selectedLanguage === option ? "bg-blue-100" : ""
                      }`}
                    >
                      <img
                        src={option.flag}
                        className="inline-block mr-1"
                        alt=""
                      />
                      {option.label}
                      {selectedLanguage === option && <></>}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="flex lg:order-2" aria-labelledby="dropdownNavbarLink">
            <div className="lg:block relative hidden" ref={dropdownRef}>
              <button
                id="dropdownDefaultButton"
                data-dropdown-toggle="dropdown"
                className="focus:ring-0 text-white leading-[26px] text-base flex items-center font-neue_montrealregular"
                type="button"
                onClick={handleDropdownToggle}
              >
                <img
                  src={selectedLanguage.flag}
                  className="inline-block mr-1"
                  alt=""
                />
                {selectedLanguage.label}
                <svg
                  className="ml-1"
                  xmlns="http://www.w3.org/2000/svg"
                  width="13"
                  height="8"
                  viewBox="0 0 13 8"
                  fill="none"
                >
                  <path
                    d="M1.01172 1.08643L6.49476 6.57221L11.9833 1.08643"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
              <div
                className={`z-10 ${
                  isDropdownOpen ? "" : "hidden"
                } bg-white absolute right-0 top-full divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700`}
              >
                <ul
                  className="py-2 text-sm text-gray-700 dark:text-gray-200"
                  aria-labelledby="dropdownDefaultButton"
                >
                  {languageOptions.map((option, index) => (
                    <li key={index}>
                      <button
                        onClick={() => handleLanguageChange(option)}
                        className={`inline-flex items-center px-2 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white w-full text-left ${
                          selectedLanguage === option ? "bg-blue-100" : ""
                        }`}
                      >
                        <img
                          src={option.flag}
                          className="inline-block mr-1"
                          alt=""
                        />
                        {option.label}
                        {selectedLanguage === option && <></>}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <button
              data-collapse-toggle="navbar-sticky"
              type="button"
              className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg lg:hidden  focus:outline-none focus:ring-0  dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              aria-controls="navbar-sticky"
              aria-expanded="false"
              onClick={handleMenu}
            >
              <span className="sr-only">Open main menu</span>
              <svg
                width="34"
                height="30"
                viewBox="0 0 34 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.771 25.9721V29.5939H0.472656V25.9721H16.771ZM33.0693 13.2956V16.9174H0.472656V13.2956H33.0693ZM33.0693 0.619141V4.24099H16.771V0.619141H33.0693Z"
                  fill="url(#paint0_linear_62_93)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_62_93"
                    x1="31.4063"
                    y1="22.304"
                    x2="-9.59133"
                    y2="11.9252"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#F247FF" />
                    <stop offset="1" stopColor="#079FFF" />
                  </linearGradient>
                </defs>
              </svg>
            </button>
          </div>
          <div
            className={`${
              menu ? "show" : ""
            }  items-center justify-between w-full lg:flex hidden lg:w-auto lg:order-1`}
          >
            <ul className="flex flex-col items-center p-4 md:p-0 lg:flex-row lg:flex lg:border-0 dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
              {menuItems.map((item, index) => (
                <li key={index}>
                  {/* Conditionally apply the "button" class */}
                  {item.button ? (
                    <button
                      onClick={() => handleButtonClick(item.link)}
                      className=" text-light-white xl:mr-[8px] mr-[4px] xl:px-5 px-3 xl:min-w-[100px] min-w-[70px]  xl:text-base text-[14px]  font-nexaxbold tracking-[0.56px] bg-btn lg:flex hidden items-center justify-center rounded-[5px] h-11   btnGrad hover:transition-all"
                    >
                      {item.label}
                    </button>
                  ) : (
                    <a
                      href={item.link}
                      className="block  text-light-white xl:text-base text-[14px] xl:px-3 px-2 lg:py-8 py-4 font-nexaregular tracking-[0.56px] hover:text-[#E696FF] transition-all"
                      aria-current="page"
                    >
                      {item.label}
                    </a>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>

        <ul className="lg:hidden flex justify-center mt-3">
          <li>
            <Link
              to="#"
              className=" text-light-white sm:text-base text-xs font-nexaxbold tracking-[0.56px] bg-btn  mr-4 ml-8 flex items-center justify-center rounded-[5px]  sm:h-11 h-[30px] sm:w-[132px] w-[84px]"
            >
              My account
            </Link>
          </li>
          <li>
            <Link
              to="#"
              className="text-light-white sm:text-base text-xs font-nexaxbold tracking-[0.56px] bg-btn flex items-center justify-center  rounded-[5px] sm:h-11 h-[30px] sm:w-[175px] w-[121px]"
            >
              Gmail
            </Link>
          </li>
          <li>
            <Link
                to="#"
                className="text-light-white sm:text-base text-xs font-nexaxbold tracking-[0.56px] bg-btn flex items-center justify-center  rounded-[5px] sm:h-11 h-[30px] sm:w-[175px] w-[121px]"
            >
              Outlook
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
