import React, { useState } from "react";
import AccordionItem from "./AccordionItem";

const FaqsWidgets = ({ data }) => {
  const [activeAccordion, setActiveAccordion] = useState(null);

  const handleAccordionClick = (accordionId) => {
    setActiveAccordion((prevState) =>
      prevState === accordionId ? null : accordionId
    );
  };

  return (
    <div>
      {data.map((item) => (
        <AccordionItem
          key={item.id}
          item={item}
          activeAccordion={activeAccordion}
          handleAccordionClick={handleAccordionClick}
        />
      ))}
    </div>
  );
};

export default FaqsWidgets;
