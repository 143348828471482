import React from "react";
import Hero from "../../components/home/Hero";
import OurEcoSystem from "../../components/home/OurEcoSystem";
import GmailPlugin from "../../components/home/GmailPlugin";
import SetUpProfile from "../../components/home/SetUpProfile";
import Faqs from "../../components/home/Faqs";
import VerificationProcess from "../../components/home/VerificationProcess";
import ForUsers from "../../components/home/ForUsers";
import ForCompanies from "../../components/home/ForCompanies";
import OurTeam from "../../components/home/OurTeam";
const Home = () => {
  return (
    <>
      <Hero />
      <OurEcoSystem />
        <ForUsers />
        <ForCompanies />
      <GmailPlugin />
      <SetUpProfile />
      <VerificationProcess />
        <Faqs />
      {/* <OurTeam /> */}
    </>
  );
};

export default Home;
