import React from "react";

const UserTable = ({ tableData }) => {
  return (
    <table className="lg:w-full md:w-[767px] w-[343px] border-spacing-[11px] border-separate">
      <thead className="">
        <tr>
          {tableData.headings.map((heading, index) => (
            <th
              key={index}
              scope="col"
              className="md:py-[39px] py-[14px]  ml-3 rounded-t-[10px] text-white xl:text-xl md:text-lg sm:text-sm text-xs  text-start font-nexaxbold xl:px-[15px] px-2 bg-btn"
            >
              {heading}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tableData.rows.map((row, rowIndex) => (
          <tr
            key={rowIndex}
            className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
          >
            {row.map((cell, cellIndex) => (
              <td
                key={cellIndex}
                className="md:pl-8 pl-[5px] md:py-[30px] py-[14px] sm:text-start text-center text-white font-nexaxbold md:text-lg text-xs capitalize bg-blue-1300 border border-blue-1400 whitespace-nowrap dark:text-white"
              >
                {cell}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default UserTable;
