import React from "react";
import { Link } from "react-router-dom";

const SimpleSteps = () => {
  return (
    <>
      <div className="text-center lg:pt-[117px] pt-20">
        <h2
          data-aos="fade-up"
          data-aos-delay="100"
          className="text-white font-clash-semibold xl:text-[42px] lg:text-[36px] md:text-[32px] text-[28px] lg:mb-14 md:mb-10 mb-8 leading-[110%]"
        >
          Steps are simple:
        </h2>
      </div>
      <div className="flex flex-wrap items-center">
        <div className="md:w-1/2 w-full">
          <div
            className="max-w-[482px] xl:mr-0 mr-5"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div className="md:text-center text-start">
              <h5
                className="xl:text-[28px] md:text-2xl text-xl font-nexaxbold bg-clip-text bg-bg-clr text-transparent"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                For users
              </h5>
            </div>
            <p
              className="text-white xl:text-xl md:text-lg text-base font-clash-semibold leading-[120%] md:mt-4 mt-5"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              If you are a person with a gmail or outlook(Not available yet):
            </p>
            <ul className="mt-5">
              <li
                data-aos="fade-up"
                data-aos-delay="300"
                className="flex items-start relative after:content-[''] after:bg-line after:bg-no-repeat after:bg-cover after:h-full after:w-1 after:absolute lg:after:top-[71px] after:top-[50px] lg:after:left-[31px] after:left-[21px]"
              >
                <span className="xl:text-[32px] lg:text-[26px] md:text-[22px] text-[26px] font-clash-bold text-blue-1600 bg-white border-[6px] border-blue-1600 rounded-full xl:w-[66px] lg:w-14 md:w-12 w-[43px] xl:h-[66px] lg:h-14 md:h-12 h-[43px] flex items-center justify-center">
                  1
                </span>
                <div className="ml-5 flex-1">
                  <p className="text-white xl:text-2xl lg:text-xl md:text-lg text-xl font-clash-semibold leading-[120%]">
                    Create your profile and what others want to see about you
                  </p>
                  <Link
                      to="https://app.mailverse.io"
                    className="text-white md:text-lg text-sm md:mt-4 mt-2 font-nexaxbold bg-btn border border-blue-1200 md:w-[179px] w-[144px] rounded-[10px] md:h-11 h-9 flex items-center justify-center  btnGrad transition-all"
                  >
                    Admin Link
                  </Link>
                </div>
              </li>
              <li
                data-aos="fade-up"
                data-aos-delay="300"
                className="flex items-start relative mt-[49px] after:content-[''] after:bg-line after:bg-no-repeat after:bg-cover after:h-full after:w-1 after:absolute lg:after:top-[71px] after:top-[50px] lg:after:left-[31px] after:left-[21px]"
              >
                <span className="xl:text-[32px] lg:text-[26px] md:text-[22px] text-[26px] font-clash-bold text-blue-1600 bg-white border-[6px] border-blue-1600 rounded-full xl:w-[66px] lg:w-14 md:w-12 w-[43px] xl:h-[66px] lg:h-14 md:h-12 h-[43px] flex items-center justify-center">
                  2
                </span>
                <div className="ml-5 flex-1">
                  <p className="text-white xl:text-2xl lg:text-xl md:text-lg text-xl font-clash-semibold leading-[120%] max-w-[318px]">
                    Once someone receives an email from you they can see more
                    than just an email
                  </p>
                </div>
              </li>
              <li
                data-aos="fade-up"
                data-aos-delay="300"
                className="flex items-start relative mt-[33px] "
              >
                <span className="xl:text-[32px] lg:text-[26px] md:text-[22px] text-[26px] font-clash-bold text-blue-1600 bg-white border-[6px] border-blue-1600 rounded-full xl:w-[66px] lg:w-14 md:w-12 w-[43px] xl:h-[66px] lg:h-14 md:h-12 h-[43px] flex items-center justify-center">
                  3
                </span>
                <div className="ml-5 flex-1">
                  <p className="text-white xl:text-2xl lg:text-xl md:text-lg text-xl font-clash-semibold leading-[120%] max-w-[300px]">
                    Download and install the plugin
                  </p>
                </div>
              </li>
            </ul>
            <Link
              data-aos="fade-up"
              data-aos-delay="300"
              to="https://workspace.google.com/marketplace/app/mailverse/127676465083"
              className="text-white lg:text-lg md:text-base text-sm md:mt-11 mt-6 font-nexaxbold bg-btn border border-blue-1200 lg:w-[301px] md:w-[244px] w-[233px] rounded-[10px] lg:h-[67px] md:h-[60px] h-[43px] flex items-center justify-center  btnGrad transition-all"
            >
              Download and install now
            </Link>
          </div>
        </div>
        <div className="md:w-1/2 w-full">
          <div className="max-w-[482px] xl:ml-auto md:ml-5 md:mt-0 mt-[63px]">
            <div className="md:text-center text-start">
              <h5
                data-aos="fade-up"
                data-aos-delay="200"
                className="xl:text-[28px] md:text-2xl text-xl font-nexaxbold bg-clip-text bg-bg-clr text-transparent"
              >
                For Companies
              </h5>
            </div>
            <p
              data-aos="fade-up"
              data-aos-delay="200"
              className="text-white xl:text-xl md:text-lg text-base font-clash-semibold leading-[120%] md:mt-4 mt-5"
            >
              If you are a company with a gmail or outlook(Not available yet):
            </p>
            <ul className="mt-5">
              <li
                data-aos="fade-up"
                data-aos-delay="300"
                className="flex items-start relative after:content-[''] after:bg-line after:bg-no-repeat after:bg-cover after:h-full after:w-1 after:absolute lg:after:top-[71px] after:top-[50px] lg:after:left-[31px] after:left-[21px]"
              >
                <span className="xl:text-[32px] lg:text-[26px] md:text-[22px] text-[26px] font-clash-bold text-blue-1600 bg-white border-[6px] border-blue-1600 rounded-full xl:w-[66px] lg:w-14 md:w-12 w-[43px] xl:h-[66px] lg:h-14 md:h-12 h-[43px] flex items-center justify-center  btnGrad transition-all">
                  1
                </span>
                <div className="ml-5 flex-1">
                  <p className="text-white xl:text-2xl lg:text-xl md:text-lg text-xl font-clash-semibold leading-[120%]">
                    Create your profile
                  </p>

                  <Link
                    to="https://app.mailverse.io"
                    className="text-white md:text-lg text-sm md:mt-4 mt-2 font-nexaxbold bg-btn border border-blue-1200 md:w-[179px] w-[144px] rounded-[10px] md:h-11 h-9 flex items-center justify-center  btnGrad transition-all"
                  >
                    Admin Link
                  </Link>
                </div>
              </li>
              <li
                data-aos="fade-up"
                data-aos-delay="300"
                className="flex items-start relative mt-[49px] after:content-[''] after:bg-line after:bg-no-repeat after:bg-cover after:h-full after:w-1 after:absolute lg:after:top-[71px] after:top-[50px] lg:after:left-[31px] after:left-[21px]"
              >
                <span className="xl:text-[32px] lg:text-[26px] md:text-[22px] text-[26px] font-clash-bold text-blue-1600 bg-white border-[6px] border-blue-1600 rounded-full xl:w-[66px] lg:w-14 md:w-12 w-[43px] xl:h-[66px] lg:h-14 md:h-12 h-[43px] flex items-center justify-center">
                  2
                </span>
                <div className="ml-5 flex-1">
                  <p className="text-white xl:text-2xl lg:text-xl md:text-lg text-xl font-clash-semibold leading-[120%] max-w-[318px]">
                    Create categories and information tabs let your users and
                    customers have new experiences with your brand:
                  </p>
                </div>
              </li>
              <li
                data-aos="fade-up"
                data-aos-delay="300"
                className="flex items-start relative mt-[33px] "
              >
                <span className="xl:text-[32px] lg:text-[26px] md:text-[22px] text-[26px] font-clash-bold text-blue-1600 bg-white border-[6px] border-blue-1600 rounded-full xl:w-[66px] lg:w-14 md:w-12 w-[43px] xl:h-[66px] lg:h-14 md:h-12 h-[43px] flex items-center justify-center">
                  3
                </span>
                <div className="ml-5 flex-1">
                  <p className="text-white xl:text-2xl lg:text-xl md:text-lg text-xl font-clash-semibold leading-[120%] max-w-[300px]">
                    Download and install the plugin
                  </p>
                </div>
              </li>
            </ul>
            <Link
              data-aos="fade-up"
              data-aos-delay="300"
              to="https://workspace.google.com/marketplace/app/mailverse/127676465083"
              className="text-white lg:text-lg md:text-base text-sm md:mt-11 mt-6 font-nexaxbold bg-btn border border-blue-1200 lg:w-[301px] md:w-[244px] w-[233px] rounded-[10px] lg:h-[67px] md:h-[60px] h-[43px] flex items-center justify-center  btnGrad transition-all"
            >
              Download and install now
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default SimpleSteps;
