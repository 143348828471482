import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import TypingEffect from "./TypingEffect";

const Hero = () => {
  useEffect(() => {
    const radius = 250;
    const fields = document.querySelectorAll(".item");
    const container = document.getElementById("container");
    const width = container.offsetWidth;
    const height = container.offsetHeight;
    const step = (2 * Math.PI) / fields.length;
    let angle = 0;

    fields.forEach((field) => {
      const x = Math.round(
        width / 2 + radius * Math.cos(angle) - field.offsetWidth / 2
      );
      const y = Math.round(
        height / 2 + radius * Math.sin(angle) - field.offsetHeight / 2
      );

      if (window.console) {
        console.log(field.textContent, x, y);
      }

      field.style.left = x + "px";
      field.style.top = y + "px";

      angle += step;
    });
  }, []);

  return (
    <section className="relative z-[1] md:pt-[66px] pt-[27px]">
      <div className="max-w-[1206px] px-4 mx-auto">
        <div className="text-center">
          <h1
            data-aos="fade-up"
            data-aos-delay="200"
            className="text-white relative z-10 mt-[29px] mb-[46px]  xl:text-[64px] lg:text-[48px] md:text-[42px] text-[28px] sm:leading-[70px] leading-[30px] font-clash-semibold"
          >
            Elevate your {""}
            <TypingEffect /> {""} <br /> experience
          </h1>
          <div className="relative text-center inline-block">
            <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 circle lg:scale-100 md:scale-[.70] scale-[.50]">
              <div id="container">
                <div className="item item-2">
                  <img src="images/tick 2.svg" alt="" />
                </div>
                <div className="item">
                  <img
                    src="images/arrow-6.svg"
                    className="rotate-[260deg]"
                    alt=""
                  />
                </div>
                <div className="item item-2">
                  <img src="images/tick 2.svg" alt="" />
                </div>
                <div className="item">
                  <img
                    src="images/arrow-4.svg"
                    className="rotate-[220deg]"
                    alt=""
                  />
                </div>
                <div className="item item-2">
                  <img src="images/tick 2.svg" alt="" />
                </div>
                <div className="item">
                  <img src="images/arrow-5.svg" alt="" />
                </div>
                <div className="item item-2">
                  <img src="images/tick 2.svg" alt="" />
                </div>
                <div className="item">
                  <img
                    src="images/arrow-3.svg"
                    className="rotate-[280deg]"
                    alt=""
                  />
                </div>
                <div className="item item-2">
                  <img src="images/tick 2.svg" alt="" />
                </div>
                <div className="item">
                  <img src="images/arrow-3.svg" alt="" />
                </div>
              </div>
            </div>
            <img
              src="images/hero-img.png"
              alt=""
              className="inline-block lg:w-auto md:w-[257px] w-[189px] lg:mt-[148px] md:mt-[100px] mt-[90px] mb-[18px]"
            />
          </div>
        </div>
        <ul  className="md:flex items-center justify-center md:mt-[62px] sm:mt-[26px] mt-4">
          <li data-aos="fade-up" data-aos-delay="100">
            <Link style={{display:"none"}}
              to="#"
              className="text-white lg:text-lg md:text-base text-sm font-nexaxbold lg:px-0 md:px-7 px-4 mx-auto bg-btn border border-blue-1200 lg:w-[380px] md:w-full w-[263px] rounded-[10px] md:h-[67px] h-[50px] flex items-center justify-center  btnGrad transition-all"
            >
              Join the waiting list as a person
            </Link>
          </li>
          <li
            data-aos="fade-up"
            data-aos-delay="200"
            className="lg:ml-12 md:ml-6 md:mt-0 mt-5"
          >
            <Link style={{display:"none"}}
              to="#"
              className="text-white lg:text-lg md:text-base text-sm font-nexaxbold lg:px-0 md:px-7 px-4 mx-auto bg-btn border border-blue-1200 lg:w-[380px] md:w-full w-[285px] rounded-[10px] md:h-[67px] h-[50px] flex items-center justify-center  btnGrad transition-all"
            >
              Join the waiting list as a company
            </Link>
          </li>
        </ul>
      </div>
      <div className="absolute top-0 z-[-1] w-full">
        <img
          src="images/hero-bg.webp"
          className="xl:h-[956px] lg:h-[874px] md:h-[697px] h-[523px] 2xl:object-cover object-fill w-full"
          alt=""
        />
      </div>
    </section>
  );
};

export default Hero;
