import React from "react";

const AccordionItem = ({ item, activeAccordion, handleAccordionClick }) => {
  const { id, question, answer } = item;

  return (
    <div
      data-aos="fade-up"
      data-aos-delay="200"
      className={`bg-blue-1300 rounded-[6px] pt-[22px] pb-6 lg:pl-10 md:pl-6 pl-[18px] md:pr-6 pr-[14px] md:mb-[22px] mb-[17px]`}
    >
      <h2 id={`accordion-collapse-heading-${id}`}>
        <button
          type="button"
          className={`flex items-center text-left justify-between w-full text-white bg-blue-1300 font-nexaxbold lg:text-2xl md:text-xl text-lg ${
            activeAccordion === id ? "active" : ""
          }`}
          onClick={() => handleAccordionClick(id)}
          aria-expanded={activeAccordion === id}
          aria-controls={`accordion-collapse-body-${id}`}
        >
          {question}
          <span className={`plus ${activeAccordion === id ? "hidden" : ""}`}>
            <img src="images/plus.svg" alt="" />
          </span>
          <span className={`cross ${activeAccordion !== id ? "hidden" : ""}`}>
            <img src="images/cross.svg" alt="" />
          </span>
        </button>
      </h2>
      {activeAccordion === id && (
        <div
          id={`accordion-collapse-body-${id}`}
          className={`dark:border-gray-700 dark:bg-gray-900 block`}
          aria-labelledby={`accordion-collapse-heading-${id}`}
        >
          <div className=" dark:border-gray-700 dark:bg-gray-900">
            <p className="text-gray-1000 md:text-base text-sm lg:mt-[14px] md:mt-[6px] mt-1 lg:pl-[30px] pl-[26px] font-nexaregular dark:text-gray-400">
              {answer}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default AccordionItem;
