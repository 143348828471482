import React from "react";
import SetUpCard from "../widgets/SetUpCard";
import SimpleSteps from "./SimpleSteps";
const cardsData = [
  {
    imageUrl: "images/admin-img1.png",
    title: "Your profile as person",
  },
  {
    imageUrl: "images/admin-img2.png",
    title: "Your profile as organization/company",
  },
  {
    imageUrl: "images/admin-img3.png",
    title: "Setup your payment methods",
  },
];
const SetUpProfile = () => {
  return (
    <section
      className="xl:pt-[70px] md:pt-11 pt-10 lg:pb-[84px] md:pb-[70px] pb-[42px]  bg-road bg-cover bg-no-repeat"
      id="process"
    >
      <div className="max-w-[1144px] px-4 mx-auto">
        <h2
          data-aos="fade-up"
          data-aos-delay="100"
          className="text-white font-clash-semibold xl:text-[42px] lg:text-[36px] md:text-[32px] text-[28px] md:text-start text-center md:mb-14 mb-6 leading-[110%]"
        >
          Setup your profile in the admin:
        </h2>
        <div className="grid md:grid-cols-3 grid-cols-1 gap-[33px]">
          {cardsData.map((card, index) => (
            <SetUpCard
              key={index}
              imageUrl={card.imageUrl}
              title={card.title}
            />
          ))}
        </div>
        <SimpleSteps />
      </div>
    </section>
  );
};

export default SetUpProfile;
