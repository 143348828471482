import React from "react";
import { Link } from "react-router-dom";

const GmailAppCard = ({ title, description, imageSrc }) => {
  return (
    <div
      data-aos="fade-up"
      data-aos-delay="200"
      className="border-[2px] border-blue-1400 bg-blue-1500 md:mt-0 mt-[50px] pt-[33px] lg:px-16 px-5 md:pb-[50px] pb-[35px] rounded-[10px] text-center"
    >
      <img
        src={imageSrc}
        alt=""
        className="inline-block lg:h-auto md:h-[135px] h-[110px]"
      />{" "}
      <h4 className="text-white font-clash-semibold xl:text-[32px] md:text-[26px] text-2xl md:mt-[18px] mt-[22px] md:mb-[17px] mb-[9px]">
        {title}
      </h4>
      <p className="font-nexaregular md:text-base text-sm text-gray-1000">
        {description}
      </p>
      <Link
        to="#"
        className="text-white md:text-lg text-sm mt-4 md:-mb-[78px] -mb-[59px] font-nexaxbold bg-btn border border-blue-1200 md:w-[187px] w-[227px] mx-auto rounded-[10px] md:h-[67px] h-11 flex items-center justify-center  btnGrad transition-all"
      >
        Get Started
      </Link>
    </div>
  );
};

export default GmailAppCard;
