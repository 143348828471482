import React from "react";
import VerificationCard from "../widgets/VerificationCard";
const data = [
  {
    id: 1,
    icon: "images/trust-icon.svg",
    title: "TRUST Animation",
  },
  {
    id: 2,
    icon: "images/time-icon.svg",
    title: " TIME Animation",
  },
  {
    id: 3,
    icon: "images/clock-icon.svg",
    title: "PRODUCTIVITY Animation",
  },
];
const VerificationProcess = () => {
  return (
    <section className="overflow-hidden">
      <div class="max-w-[1196px] mx-auto px-4 ">
        <h2
          data-aos="fade-up"
          data-aos-delay="100"
          className="text-white font-clash-semibold md:text-start text-center xl:text-[42px] lg:text-[36px] md:text-[32px] text-[28px] md:mb-14 mb-9 leading-[110%]"
        >
          Advance identity verification process to avoid spam
        </h2>
        <div class="flex flex-wrap justify-center lg:-mx-[30px] md:-mx-[5px] -mx-[15px]">
          <VerificationCard data={data} />
        </div>
      </div>
    </section>
  );
};

export default VerificationProcess;
