import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";
import Header from './components/layouts/header/Header';
import Home from "./pages/home";
import Footer from "./components/layouts/footer/Footer";

import logo from "./assets/images/logo-3.png";
import flagEN from "./assets/images/flag.svg";
import flagFR from "./assets/images/flag.svg";


const aboutData = [
  { id: 1, title: "About us", link: "#" },
  { id: 2, title: "About Users", link: "#" },
  { id: 3, title: "Mailverse", link: "#" },
];

const linksData = [
  { id: 1, title: "Acceptable Use Policy", link: "#" },
  { id: 2, title: "Data Processing Policy", link: "#" },
  { id: 3, title: "Company Information", link: "#" },
  { id: 4, title: "Addons", link: "#" },
];

const supportData = [
  { id: 1, title: "Contact us", link: "mail:danny@mailverse.io" },
  { id: 2, title: "Privacy and policy", link: "https://mailverse.io/privacypolicy.html" },
  { id: 3, title: "Terms And condition", link: "https://mailverse.io/terms.html" },
];

// Data for social media icons
const socialIconsData = [
  { link: "https://facebook.com/mailverse", src: "images/facebook.svg", alt: "Facebook" },
  { link: "https://www.linkedin.com/company/mailverseio/", src: "images/linkdin.svg", alt: "LinkedIn" },
  { link: "https://twitter.com/mailverse", src: "images/twiter.svg", alt: "Twitter" },
  { link: "https://www.instagram.com/mailverse/", src: "images/instagram.svg", alt: "Instagram" },
];

const menuItems = [
  { label: "Home", link: "/" },
  { label: "Ecosystem", link: "#benefits" },
  { label: "Process", link: "#process" },
  { label: "Users", link: "#users" },
  { label: "Companies", link: "#companiees" },
  //{ label: "Team", link: "#team" },
  { label: "FAQ", link: "#faq" },
  { label: "App", link: "https://app.mailverse.io", button: true },
  { label: "Gmail", link: "https://workspace.google.com/marketplace/app/mailverse/127676465083", button: true },
  { label: "Outlook", link: "https://appsource.microsoft.com/en-us/product/office/WA200006042", button: true },
];

// Sample data for language options
const languageOptions = [
  { label: "EN", flag: flagEN },
  { label: "ES", flag: flagFR },
];




function App() {
  return (
    <Router>
      <Header logoSrc={logo} menuItems={menuItems} languageOptions={languageOptions} />

      <Routes>
        <Route exact path="/" element={<Home />} />
      </Routes>
      <Footer
        aboutData={aboutData}
        linksData={linksData}
        supportData={supportData}
        socialIconsData={socialIconsData}
      />
    </Router>
  );
}

export default App;
