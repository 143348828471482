import React from "react";
import { Link } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";

const Footer = (props) => {
  const { aboutData, linksData, supportData, socialIconsData } = props;

  return (
    <footer class="bg-blue-1300 sm:py-14 pt-11 pb-9 lg:mt-[138px] sm:mt-[70px] mt-11">
      <div class="max-w-[1196px] mx-auto px-4 relative">
        <div class="flex flex-wrap">
          <div class="lg:w-[44%] md:w-1/3 w-full md:text-start text-center">
            <img src="images/logo.svg" className="inline-block" alt="" />
            <p class="sm:text-base text-sm font-nexaregular text-gray-1200 sm:mt-6 mt-4">
              Copyright © 2023 Mailverse all rights reserved.
            </p>
            <ul className="flex items-center md:justify-start justify-center sm:mt-8 mt-4">
              {socialIconsData.map((icon, index) => (
                <li key={index}>
                  <Link
                    to={icon.link}
                    className="socialIcon relative overflow-hidden hover:bg-transparent before:transition-all hover:before:top-0 bg-white h-9 w-9 flex items-center before:content-[''] before:bottom-0 before:left-0 before:right-0 before:bg-btn  before:absolute social-icon justify-center rounded-full mr-3"
                  >
                    <img src={icon.src} alt={icon.alt} />
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div class="lg:w-[56%] md:w-2/3 md:mt-0 mt-[30px] w-full lg:pl-0 pl-5">
            <div className="grid sm:grid-cols-3 grid-cols-2 lg:gap-0 gap-4">
              <div style={{ display: "none" }}>
                <h5 className="text-white font-nexaxbold text-xl sm:mb-6 mb-[18px]">
                  About
                </h5>
                <ul>
                  {/* Use map to render the About section links dynamically */}
                  {aboutData.map((item) => (
                    <li key={item.id}>
                      <Link
                        to={item.link}
                        className="xl:text-base text-sm font-nexaregular sm:mb-6 mb-[9px] flex  text-gray-1200 hover:text-[#E696FF] transition-all"
                      >
                        {item.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div style={{ display: "none" }}>
                <h5 className="text-white font-nexaxbold text-xl sm:mb-6 mb-[18px]">
                  Links
                </h5>
                <ul>
                  {/* Use map to render the Links section links dynamically */}
                  {linksData.map((item) => (
                    <li key={item.id}>
                      <Link
                        to={item.link}
                        className="xl:text-base text-sm font-nexaregular sm:mb-6 mb-[9px] flex  text-gray-1200 hover:text-[#E696FF] transition-all"
                      >
                        {item.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div>
                <h5 className="text-white font-nexaxbold text-xl sm:mb-6 mb-[18px]">
                  Support
                </h5>
                <ul>
                  {/* Use map to render the Support section links dynamically */}
                  {supportData.map((item) => (
                    <li key={item.id}>
                      <Link
                        to={item.link}
                        className="xl:text-base text-sm font-nexaregular sm:mb-6 mb-[9px] flex  text-gray-1200  hover:text-[#E696FF] transition-all"
                      >
                        {item.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <ScrollToTop />
      </div>
    </footer>
  );
};

export default Footer;
