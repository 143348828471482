// src/components/TypingEffect.js

import React, { useEffect, useState } from "react";
import Typewriter from "typewriter-effect/dist/core";
import GraphemeSplitter from "grapheme-splitter";

const TypingEffect = () => {
  const [textIndex, setTextIndex] = useState(0);
  const texts = ["email", "mailing"];

  useEffect(() => {
    const container = document.getElementById("typeEffect");

    const stringSplitter = (string) => {
      const splitter = new GraphemeSplitter();
      return splitter.splitGraphemes(string);
    };

    const typewriter = new Typewriter(container, {
      loop: true,
      delay: 75,
      stringSplitter,
    });

    typewriter
      .pauseFor(1000)
      .typeString(texts[textIndex])
      .pauseFor(1000)
      .deleteAll()
      .callFunction(() => {
        setTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
      })
      .start();

    return () => typewriter.stop(); // Stop the typewriter on component unmount
  }, [textIndex, texts]);

  return (
    <span className="bg-clip-text bg-bg-clr text-transparent" id="typeEffect" />
  );
};

export default TypingEffect;
