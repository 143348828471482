import { Link } from "react-router-dom";
import CardEco from "../widgets/CardEco";
import React from "react";

const cardData = [
  {
    title: "Gmail Add On",
    date: "July 20th",
    imageSrc: "images/mail-icon.svg",
    link:"https://workspace.google.com/marketplace/app/mailverse/127676465083"
  },
  {
    title: " Outlook AddIn",
    date: "September 27th",
    imageSrc: "images/outlook.svg",
    link:"https://appsource.microsoft.com/en-us/product/office/WA200006042"
  },
  {
    title: "Email client",
    date: "2024",
    imageSrc: "images/client-icon.svg",
    link:"#"
  },
  {
    title: "   Whatsapp Bo",
    date: "2024",
    imageSrc: "images/bot-icon.svg",
    link:"#"
  },
];

const OurEcoSystem = () => {
  return (
    <section className="md:mt-[66px] mt-[33px]" id="benefits">
      <div className="max-w-[1144px] px-4 mx-auto">
        <h2
          data-aos="fade-up"
          data-aos-delay="100"
          className="text-white font-clash-semibold md:text-start text-center xl:text-[42px] lg:text-[36px] md:text-[32px] text-[28px] lg:mb-[62px] md:mb-7 mb-[33px]"
        >
          Our ecosystem
        </h2>

        <div className="grid lg:grid-cols-4 grid-cols-2 sm:gap-[26px] gap-[22px]">
          {cardData.map((data, index) => (
            <CardEco
              key={index}
              title={data.title}
              date={data.date}
              imageSrc={data.imageSrc}
              link={data.link}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default OurEcoSystem;
