import React from "react";

const EmailCampaigns = ({ surveys }) => {
  return (
    <>
      {surveys.map((survey) => (
        <div
          key={survey.id}
          className="groupImg bg-blue-1300 rounded-[10px] px-4 sm:pt-[38px] pt-5 sm:pb-[27px] pb-[17px]"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <div className="text-center">
            <img
              src={survey.icon}
              alt=""
              className="inline-block sm:h-auto h-[63px]"
            />
            <h6 className="text-white xl:text-[28px] sm:text-2xl text-lg font-nexaxbold tracking-[0.98px] sm:leading-[36px] leading-5 lg:mt-7 mt-[17px]">
              {survey.title}
            </h6>
          </div>
        </div>
      ))}
    </>
  );
};

export default EmailCampaigns;
