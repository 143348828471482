import React from "react";

const SetUpCard = ({ imageUrl, title }) => {
  return (
    <div className="text-center" data-aos="fade-up" data-aos-delay="200">
      <img src={imageUrl} alt="" className="inline-block" />
      <h4 className="text-white font-clash-semibold xl:text-[28px] lg:text-2xl md:text-xl text-2xl leading-[110%] md:mt-[33px] mt-5">
        {title}
      </h4>
    </div>
  );
};

export default SetUpCard;
