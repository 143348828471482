import React from "react";

const VerificationCard = ({ data }) => {
  return (
    <>
      {data.map((item) => (
        <div
          className="md:w-1/3 w-1/2 lg:px-[30px] md:px-[5px] px-[15px] mb-[30px]"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <div
            key={item.id}
            className="groupImg bg-blue-1300 pt-11 pb-[50px] rounded-[10px] md:h-[229px] h-[154px] flex items-center justify-center"
          >
            <div className="text-center">
              <img
                src={item.icon}
                className="inline-block md:w-auto w-8"
                alt="#"
              />
              <h5 className="text-white font-nexaxbold sm:px-0 px-2 xl:text-[28px] lg:text-2xl text-xl md:mt-5 mt-4">
                {item.title}
              </h5>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default VerificationCard;
