import React from "react";
import EmailCampaigns from "../widgets/EmailCampaigns";

const surveys = [
  {
    id: 1,
    icon: "images/survey-icon.svg",
    title: "Add surveys",
  },
  {
    id: 2,
    icon: "images/forms-icon.svg",
    title: "Add forms",
  },
  {
    id: 3,
    icon: "images/payment-icon.svg",
    title: "Enable payments",
  },
  {
    id: 4,
    icon: "images/enable-icon.svg",
    title: "Automation Triggers",
  },
];

const ForCompanies = () => {
  return (
    <section
      className="xl:pt-[130px] pt-20 xl:pb-[109px] sm:pb-20 pb-[55px]"
      id="companiees"
    >
      <div className="max-w-[1196px] mx-auto px-4 ">
        <div className="text-center">
          <h5
            data-aos="fade-up"
            data-aos-delay="100"
            className="xl:text-[28px] text-2xl font-nexaxbold bg-clip-text bg-bg-clr text-transparent"
          >
            For Companies
          </h5>
          <h2
            data-aos="fade-up"
            data-aos-delay="200"
            className="text-white font-clash-semibold lg:text-4xl sm:text-3xl text-[28px] sm:leading-normal leading-[33px] max-w-[712px] mx-auto my-2.5"
          >
            Regain the control of your emails and increaser your customer
            loyalty
          </h2>{" "}
          <h5
            data-aos="fade-up"
            data-aos-delay="200"
            className="lg:text-[28px] sm:text-2xl text-xl font-clash-semibold bg-clip-text bg-bg-clr text-transparent"
          >
            Start receiving payments directly from your email campaigns
          </h5>
        </div>
        <div className="grid lg:grid-cols-4 grid-cols-2 gap-[26px] xl:mt-[98px] lg:mt-20 mt-8">
          <EmailCampaigns surveys={surveys} />
        </div>
      </div>
    </section>
  );
};

export default ForCompanies;
