import React from "react";
import FaqsWidgets from "../widgets/FaqsWidgets";
const data = [
  {
    id: 1,
    question: "Q. How to install the plugin?",
    answer: "Create an account in https://app.mailverse.io/",
  },
  {
    id: 2,
    question: "Q. How to setup my profile?",
    answer: "Create an account in https://app.mailverse.io/",
  },
  {
    id: 3,
    question: "Q.  For Gmail Users in mobile phone",
    answer: "Create an account in https://app.mailverse.io/",
  },
  {
    id: 4,
    question: "Q.  For gmail users in a browser client?",
    answer: "Create an account in https://app.mailverse.io/",
  },
  {
    id: 5,
    question: "Q.  For Outlook users?",
    answer: "Create an account in https://app.mailverse.io/",
  },
];
const Faqs = () => {
  return (
    <section id="faq">
      <div className="max-w-[1196px] mx-auto px-4 lg:pt-[86px] md:pt-[70px] pt-[30px] lg:pb-20 pb-12">
        <div className="text-center">
          <h2
            data-aos="fade-up"
            data-aos-delay="100"
            className="text-white font-clash-semibold xl:text-[42px] lg:text-[36px] md:text-[32px] text-[28px] md:mb-14 mb-[38px] leading-[110%]"
          >
            Frequently Asked Questions
          </h2>
        </div>
        <FaqsWidgets data={data} />
      </div>
    </section>
  );
};

export default Faqs;
