import React from "react";
import { Link } from "react-router-dom";

const ScrollToTop = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <Link
      to="#"
      className="bg-btn lg:w-[63px] w-12 lg:h-[63px] h-12 absolute top-[-88px] lg:right-0 right-8 rounded-full sm:flex hidden items-center justify-center"
      onClick={scrollToTop}
    >
      <img src="images/arrow.svg" alt="" />
    </Link>
  );
};

export default ScrollToTop;
