import React from "react";
import UserTable from "../widgets/UserTable";
const tableData = {
  headings: [
    "Find An Important Email",
    "Make A Payment In 3 Clicks",
    "Leave A Review In 3 Clicks",
    "Fill A Survey In Record Time",
  ],
  rows: [
    ["Average", "Average", "Average", "Average"],
    ["5 minutes", "5 minutes", "8 mins", "5 mins"],
    ["45 secs", "45 secs", "1.5 mins", "1.5 mins"],
  ],
};

const ForUsers = () => {
  return (
    <section className="xl:pt-[122px] md:pt-[70px] pt-[97px]" id="users">
      <div className="max-w-[1196px] mx-auto px-4 ">
        <div className="text-center">
          <h5
            data-aos="fade-up"
            data-aos-delay="100"
            className="xl:text-[28px] text-2xl font-nexaxbold bg-clip-text bg-bg-clr text-transparent md:mb-0 mb-2.5"
          >
            For users
          </h5>
          <h2
            data-aos="fade-up"
            data-aos-delay="200"
            className="text-white font-clash-semibold xl:text-[42px] lg:text-[36px] md:text-[32px] text-[28px] sm:leading-[46px] leading-[36px] mb-11"
          >
            Increase the security and the productivity of your inbox
          </h2>
        </div>
        <div className="flex items-end">
          <div className="mb-2.5">
            <div
              className="bg-btn py-[13px] md:px-[33px] px-2 md:max-w-[258px] max-w-[83px]"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <h6 className="text-white md:text-2xl text-base font-clash-semibold">
                Current email clients
              </h6>
            </div>
            <div
              className="bg-btn py-[13px] mt-2.5 md:px-[33px] px-2 md:max-w-[258px] max-w-[83px]"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <h6 className="text-white text-break md:text-2xl text-base font-clash-semibold">
                With
                <br />
                mailverse
              </h6>
            </div>
          </div>
          <div
            className="relative scroll-bar overflow-x-auto flex-1 lg:ml-0 ml-4"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <UserTable tableData={tableData} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForUsers;
