import React from "react";
import { Link } from "react-router-dom";

const CardEco = ({ title, date, imageSrc,link }) => {
  return (
    <Link to={link}>
      <div
        className="bg-blue-1300 groupImg rounded-[10px] md:pt-[50px] pt-[25px] md:pb-7 pb-[18px] sm:px-[30px] px-2.5 text-center"
        data-aos="fade-up"
        data-aos-delay="200"
      >
        <img
          src={imageSrc}
          className="inline-block md:h-[68px] h-[36px]"
          alt=""
        />
        <h4 className="text-white xl:text-[28px] md:text-[22px] text-lg  font-nexaxbold md:mt-8 mt-[14px] mb-2">
          {title}
        </h4>
        <h6 className="md:text-lg text-sm font-nexaregular text-white">
          {date}
        </h6>
      </div>
    </Link>
  );
};

export default CardEco;
