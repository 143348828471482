import React from "react";
import GmailAppCard from "../widgets/GmailAppCard";
const cardData = [
  {
    title: "In Gmail Mobile App",
    description: "Go to the button of the email and open the Mailverse icon",
    imageSrc: "images/app-icon.svg",
  },
  {
    title: "In your Web Email client ",
    description:
      "Open the sidebar of your inbox when you open an email to see the Mailverse icon",
    imageSrc: "images/page-icon.svg",
  },
];
const GmailPlugin = () => {
  return (
    <section className="lg:pb-[115px] pb-20 lg:pt-20 pt-16">
      <div className="max-w-[1144px] px-4 mx-auto">
        <div className="text-center">
          <h2
            className="text-white font-clash-semibold xl:text-[42px] lg:text-[36px] md:text-[32px] text-[28px] lg:mb-[54px] mb-0"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            GmailPlugin
          </h2>
        </div>
        <div className="grid md:grid-cols-2 grid-cols-1 lg:gap-[60px] gap-5">
          {cardData.map((data, index) => (
            <GmailAppCard
              key={index}
              title={data.title}
              description={data.description}
              imageSrc={data.imageSrc}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default GmailPlugin;
